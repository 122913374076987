#profile-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar-container {
  width: 250px;  /* 增大头像容器 */
  height: 250px; /* 增大头像容器 */
  border-radius: 50%;
  background-color: #ffd1dc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

#profile-avatar {
  width: 230px;  /* 增大头像 */
  height: 230px; /* 增大头像 */
  border-radius: 50%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.avatar-container:hover #profile-avatar {
  filter: brightness(70%);
}

.avatar-hover-text {
  position: absolute;
  color: white;
  font-size: 16px;
  text-align: center;
  pointer-events: none;
}

.avatar-tip {
  color: #888;
  font-size: 14px;
  margin-bottom: 20px;
}

.info-container {
  background-color: #ffd1dc;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.info-item strong {
  flex: 1;
}

.info-item span, .info-item .motto-display, .info-item .motto-edit {
  flex: 2;
}

.motto-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.motto-edit {
  display: flex;
  flex-direction: column;
}

.motto-edit input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ffb3c6;
  border-radius: 4px;
}

.motto-buttons {
  display: flex;
  justify-content: flex-end;
}

button {
  padding: 5px 10px;
  margin-left: 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-btn {
  background-color: #FF00FF; /* 品红色 */
  color: white;
}

.save-btn {
  background-color: #4CAF50; /* 绿色 */
  color: white;
}

.cancel-btn {
  background-color: #f44336; /* 红色 */
  color: white;
}

button:hover {
  opacity: 0.8;
}

.logout-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;  /* 使登出按钮与信息容器同宽 */
  max-width: 800px;  /* 限制最大宽度 */
}

.logout-btn:hover {
  background-color: #c82333;
}

@media (max-width: 768px) {
  .profile-info {
    flex-direction: column;
    align-items: center;
  }

  .avatar-container {
    width: 150px;  /* 调整移动端头像大小 */
    height: 150px; /* 调整移动端头像大小 */
    margin-right: 0;
    margin-bottom: 20px;
  }

  #profile-avatar {
    width: 140px;  /* 调整移动端头像大小 */
    height: 140px; /* 调整移动端头像大小 */
  }

  .info-container {
    width: 100%;
  }
}