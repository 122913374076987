.all-grades-page {
  padding: 20px;
}

.search-filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.search-filters input,
.search-filters select {
  padding: 5px;
  font-size: 16px;
}

.grades-table {
  width: 100%;
  border-collapse: collapse;
}

.grades-table th,
.grades-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.grades-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.grades-table tr:nth-child(even) {
  background-color: #f9f9f9;
}