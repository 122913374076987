body {
    font-family: 'Microsoft YaHei', Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
}

/* 动态背景 */
#home {
    position: relative;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

#dynamic-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.background-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(45deg, #3498db, #8e44ad);
}

/* 其他基础样式 */