.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff; /* 将背景色改为白色 */
}

.login-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.login-container form {
  display: flex;
  flex-direction: column;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.login-container input, 
.login-container select {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.login-container .class-input {
  background-color: #f0f0f0;
  color: #333;
}

.login-container button {
  padding: 10px;
  background-color: #00a1d6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0086b3;
}

.role-and-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.role-and-class select {
  flex: 1;
  margin-right: 10px;
  margin-bottom: 0;
}

.class-info {
  flex: 1;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.login-container input[type="password"],
.login-container input[type="text"] {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.login-message {
  margin-top: 15px;
  padding: 10px;
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  border-radius: 4px;
  color: #2e7d32;
  text-align: center;
}