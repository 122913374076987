/* 这里添加首页特定的样式 */

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px); /* 减去header的高度 */
  padding: 20px;
}

.home-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.home-content h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.home-content p {
  font-size: 1.2em;
  color: #666;
  line-height: 1.6;
}