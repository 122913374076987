#resources-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f4f8;
}

.resource-section {
    background-color: #ffffff;
    border: 2px solid #3498db;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resource-section h3 {
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.form-container {
    background-color: #ecf0f1;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.form-container input[type="text"],
.form-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
    font-size: 16px;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.form-container button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}

.form-container button:hover {
    background-color: #27ae60;
}

.question, .resource {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question h4, .resource h4 {
    color: #3498db;
    margin-bottom: 10px;
    font-size: 18px;
}

.question-content, .resource p {
    color: #34495e;
    margin-bottom: 10px;
    font-size: 16px;
}

.question-meta, .resource-meta {
    font-size: 14px;
    color: #7f8c8d;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.answer-btn {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
}

.answer-btn:hover {
    background-color: #2980b9;
}

.answers {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 2px solid #3498db;
}

.answer {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}