.loading-bar {
  height: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  visibility: hidden;
}

.loading-bar.loading {
  visibility: visible;
}

.loading-bar .bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #4CAF50;
  animation: loading 1s infinite ease-in-out;
}

@keyframes loading {
  0% {
    left: -100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}