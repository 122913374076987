#announcements-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

#announcements-page h1 {
    text-align: center;
    margin-bottom: 20px;
}

.notice-board, .timeline {
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

.notice-board {
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
}

.timeline {
    background-color: transparent;
}

.notice-board h2, .timeline h2 {
    margin-bottom: 15px;
}

button {
    border: none;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}

.publish-btn {
    background-color: #28a745;
}

.delete-btn {
    background-color: #dc3545;
}

.add-btn {
    background-color: #007bff;
}

button:hover {
    opacity: 0.8;
}

/* 添加更多样式以匹配原有的布局 */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 80%;
    max-width: 500px;
}

.modal input, .modal textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
}

.modal button {
    margin-right: 10px;
}

.delete-btn {
    color: red;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
}

.notice-item, .timeline-event {
    position: relative;
    padding-right: 30px;
}

.notice-image, .event-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
}

.notice-info, .event-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    color: #666;
    margin-top: 10px;
}

.teacher-actions button {
    border: none;
    padding: 10px 15px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}

.teacher-actions button:first-child {
    background-color: #28a745;  /* 发布公告按钮的颜色 */
}

.teacher-actions button:last-child {
    background-color: #dc3545;  /* 删除公告按钮的颜色 */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    width: 80%;
    max-width: 500px;
}

.modal h3 {
    margin-top: 0;
}

.modal input, 
.modal textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal button {
    padding: 5px 10px;
    cursor: pointer;
}

/* ... 保持其他样式不变 */