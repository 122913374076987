header {
    background-color: #f8f9fa;
    padding: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.class-name {
    font-size: 1.5em;
    margin: 0;
    color: #4a90e2; /* 浅蓝色 */
}

nav {
    display: flex;
    gap: 20px;
}

.nav-link {
    text-decoration: none;
    color: #333;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 20px;
    transition: all 0.3s ease;
}

.nav-link:hover {
    background-color: #4a90e2; /* 使用与 class-name 相同的蓝色 */
    color: #fff; /* 悬停时文字变为白色 */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 添加轻微阴影效果 */
}

.user-actions {
    display: flex;
    align-items: center;
}

.user-profile {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.login-btn {
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    padding: 10px 15px;
    border-radius: 20px;
    transition: all 0.3s ease;
}

.login-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* 移除了 .logout-btn 样式 */

/* ... 其他样式保持不变 */