.publish-grades {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow-x: auto;
}

.exam-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.exam-info select,
.exam-info input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  min-width: 600px;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

input[type="number"] {
  width: 60px;
  padding: 5px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .exam-info {
    flex-direction: column;
  }

  .exam-info select,
  .exam-info input {
    width: 100%;
    margin-bottom: 10px;
  }
}